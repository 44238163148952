<template>
    <div class="activies">
        <div class="list_container">
            <div class="list_title">{{ info.title }}</div>
            <div class="list_time">
                {{ "发表于" | language }}{{ info.writeAt }}
            </div>
            <div class="list_content mr-b4" v-html="info.content">
                {{ info.content }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            id: "",
            info: null,
        };
    },
    mounted() {
        this.id = this.$route.params.id;
        this.fetchData();
    },
    methods: {
        async fetchData() {
            let res = await this.$http.get(`/information/news/${this.id}`);
            this.info = res;
        },
    },
};
</script>
<style lang="less" scoped>
.activies {
    .list_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .list_title {
            padding-bottom: 10px;
            opacity: 0.9;
            font-size: 30px;
            color: #000000;
            letter-spacing: 0;
            line-height: 32px;
        }
        .list_time {
            font-size: 16px;
            opacity: 0.3;
            color: #000000;
        }
        .list_content {
            margin-top: 20px;
            text-align: left;
            font-size: 16px;
            color: #666666;
            line-height: 29px;
        }
    }
}
</style>
<style>
.list_content img {
    max-width: 100%;
}
</style>
